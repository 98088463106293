import { defineStore } from "pinia";
import { apiBackendAuthAxios } from "@/axiosAuth.js";

export const useVideoStore = defineStore("video", {
  state: () => {
    return {
      isMuted: false,
      volume: 1,
      lastWatchedTime: {},
      watchedVideos: {},
      showSensitiveContent: {},
      videoViewQueue: [],
    };
  },
  actions: {
    updateMuteState(isMuted) {
      this.isMuted = isMuted;
    },
    updateVolume(volume) {
      this.volume = volume;
    },
    updateLastWatchedTime(userId, videoId, time) {
      if (!this.lastWatchedTime[userId]) {
        this.lastWatchedTime[userId] = {};
      }
      this.lastWatchedTime[userId][videoId] = time;
    },
    markAsWatched(userId, videoId) {
      this.$patch((state) => {
        if (!state.watchedVideos[userId]) {
          state.watchedVideos[userId] = [];
        }
        if (!state.watchedVideos[userId].includes(videoId)) {
          state.watchedVideos[userId].push(videoId);
        }
      });
    },
    updateSensitiveContent(videoId, isSensitive) {
      this.showSensitiveContent[videoId] = isSensitive;
    },
    resetVideoState() {
      this.isMuted = false;
      this.volume = 1;
      this.lastWatchedTime = {};
    },

    async addVideoViewToQueue({
      videoId,
      startInSeconds,
      endInSeconds,
      lastPosition,
    }) {
      this.videoViewQueue.push({
        post_ulid: videoId,
        start_second: Math.round(startInSeconds),
        end_second: Math.round(endInSeconds),
        last_position: Math.round(lastPosition),
      });

      if (this.videoViewQueue.length >= 20) {
        await this.sendBatchedVideoViews();
      }
    },

    async sendBatchedVideoViews() {
      if (this.videoViewQueue.length === 0) {
        return;
      }

      const viewsToSend = JSON.parse(JSON.stringify(this.videoViewQueue));

      this.videoViewQueue = [];

      try {
        await apiBackendAuthAxios.post("/video-views", {
          views: viewsToSend,
        });
      } catch (error) {
        console.error("Failed to send batch video views:", error);
      }
    },
  },
  persist: true,
  share: {
    enable: true,
    initialize: true,
  },
});

<template>
  <div id="modal-container"></div>
  <NavBar />
  <div class="subheader">
    <aside
      :class="`${isExpanded ? 'is-expanded' : ''}`"
      class="h-[100%] max-w-1/4"
      id="sidebar"
    >
      <SidebarAuthenticated v-if="authenticated" />
      <SidebarUnauthenticated v-else />
    </aside>
    <div class="aside-cover" @click="hideSidebar"></div>
    <div
      id="main-content"
      class="md:mx-auto md:w-6/12 subheader"
      :class="{
        ['sidebar-expanded']: isExpanded,
        ['sidebar-collapsed']: !isExpanded,
      }"
      :style="{
        paddingLeft: `${sidebarWidth}px`,
      }"
    >
      <AppBanner />
      <router-view />
    </div>
  </div>
  <ModalsContainer />
</template>
<script setup>
import { ModalsContainer } from "vue-final-modal";
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import "./assets/main.scss";
import NavBar from "@/components/navbar/NavBar.vue";
import AppBanner from "./components/AppBanner.vue";
import SidebarAuthenticated from "./components/sidebar/SidebarAuthenticated.vue";
import SidebarUnauthenticated from "./components/sidebar/SidebarUnauthenticated.vue";
import { useSidebarStore } from "@/stores/sidebarStore.tsx";
import router from "@/router/index.js";
import { useAuthStore } from "@/stores/auth.tsx";
import { useUserStore } from "@/stores/userStore.tsx";
import { useVideoStore } from "@/stores/videoStore.tsx";
import { useGlobalStore } from "./stores/globalStore";
import { useDarkModeStore } from "@/stores/darkModeStore.tsx";
import { useMobileDetection } from "@/composables/useMobileDetection";

const sidebarStore = useSidebarStore();
const authStore = useAuthStore();
const userStore = useUserStore();
const videoStore = useVideoStore();
const globalStore = useGlobalStore();
const darkModeStore = useDarkModeStore();

const authenticated = computed(() => {
  return authStore.authenticated;
});

const userDarkMode = computed(() => {
  if (!authenticated.value) {
    return null;
  }

  return userStore.darkMode;
});

const darkMode = computed(() => {
  return userDarkMode.value !== null
    ? userStore.darkMode
    : darkModeStore.darkMode;
});

const handleDarkMode = (darkModeValue) => {
  if (darkModeValue === "system") {
    darkModeValue = "light";
    if (window.matchMedia) {
      darkModeValue = window.matchMedia("(prefers-color-scheme:light)").matches
        ? "dark"
        : "light";
    }
  }

  if (darkModeValue) {
    if (!document.documentElement.classList.contains("dark")) {
      document.documentElement.classList.toggle("dark");
    }

    document.documentElement.classList.add("dark");
    return;
  }

  if (document.documentElement.classList.contains("dark")) {
    document.documentElement.classList.toggle("dark");
  }

  document.documentElement.classList.remove("dark");
};

watch([authenticated, userDarkMode], () => {
  if (!authenticated.value) {
    return;
  }

  if (userDarkMode.value) {
    darkModeStore.setDarkMode();
  } else {
    darkModeStore.setLightMode();
  }
});

handleDarkMode(darkMode.value);

watch([darkMode], () => {
  handleDarkMode(darkMode.value);
});

const accountSetup = computed(() => {
  return userStore.accountSetup;
});

const acceptTermsOfService = computed(() => {
  return userStore.acceptTermsOfService;
});

const loading = computed(() => {
  return userStore.loading;
});

if (authStore.authenticated && userStore.loading) {
  userStore.refreshUserState();
}

watch([authenticated, accountSetup, acceptTermsOfService, loading], () => {
  if (
    authenticated.value === true &&
    loading.value === false &&
    acceptTermsOfService.value === false
  ) {
    if (router.currentRoute.value.name !== "AcceptTermsAndConditions") {
      router.push({ path: "/setup/terms-and-conditions" });
    }
    return;
  }

  if (
    authenticated.value === true &&
    loading.value === false &&
    accountSetup.value === false
  ) {
    if (router.currentRoute.value.name !== "SetupAccount") {
      router.push({ path: "/setup/account" });
    }
    return;
  }

  if (
    authenticated.value === true &&
    loading.value === false &&
    accountSetup.value === true &&
    router.currentRoute.value.name === "SetupAccount"
  ) {
    router.push({ path: "/" });
    return;
  }

  if (
    authenticated.value === true &&
    loading.value === false &&
    acceptTermsOfService.value === true &&
    router.currentRoute.value.name === "AcceptTermsAndConditions"
  ) {
    router.push({ path: "/" });
    return;
  }

  if (
    router.currentRoute.value.meta.onlyPrivate === true &&
    !authenticated.value
  ) {
    router.push({ path: "/" });
  }

  if (
    router.currentRoute.value.meta.onlyPublic === true &&
    authenticated.value
  ) {
    if (
      typeof router.currentRoute.value.query !== "undefined" &&
      typeof router.currentRoute.value.query.redirect !== "undefined"
    ) {
      router.push({ path: router.currentRoute.value.query.redirect });
    } else {
      router.push({ path: "/" });
    }
  }
});

const { mobile } = useMobileDetection();

watch(mobile, (newVal) => {
  globalStore.isMobile = newVal;
});

const sidebarEl = ref(null);

const sidebarWidth = ref(0);

const isExpanded = computed(() => {
  return sidebarStore.isExpanded();
});

const hideSidebar = () => {
  sidebarStore.hide();
};

let resizeObserver;

const getSidebarWidth = () => {
  const sidebarElement = document.getElementById("sidebar");
  if (sidebarElement) {
    sidebarWidth.value = sidebarElement.getBoundingClientRect().width;
  }
};
watch(isExpanded, () => getSidebarWidth());

onMounted(() => {
  sidebarEl.value = document.getElementById("sidebar");
  if (sidebarEl.value) {
    resizeObserver = new ResizeObserver(() => {
      getSidebarWidth();
    });
    resizeObserver.observe(sidebarEl.value);
  }

  if (globalStore.reactionTypes.length === 0) {
    globalStore.setReactionTypes();
  }
});

onUnmounted(() => {
  videoStore.sendBatchedVideoViews();
  if (resizeObserver && sidebarEl.value) {
    resizeObserver.unobserve(sidebarEl.value);
  }
});
</script>
<style lang="scss" scoped>
@import "./assets/styles/mixins.scss";
@import "./components/sidebar/css/sidebar.scss";

.alert {
  top: 56px !important;
}

.subheader {
  margin-top: 50px;
  background-color: #000;
  width: 100%;
}

#main-content {
  background-color: var(--background-color);
  height: calc(100svh - var(--navbar-height));
  // height: calc(100vh - var(--navbar-height));
  overflow: auto;
  color: var(--primary);

  @media (max-width: 1080px) {
    & {
      padding-left: 64px;
    }
  }

  @media (max-width: 480px) {
    & {
      padding-left: 0px !important;
    }
  }
}

/* Custom scrollbar for WebKit browsers */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-accent);
  /* Use primary color variable */
  border-radius: 10px;

  border: 2px solid transparent;

  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-accent) !important;
}

/* Custom scrollbar for Firefox */
* {
  scrollbar-width: auto;

  scrollbar-color: var(--primary-accent) transparent;
}
</style>

import { defineStore } from "pinia";

export const useDarkModeStore = defineStore("darkMode", {
  state: () => ({
    darkMode: false,
  }),
  actions: {
    isDarkModeOn() {
      return this.darkMode;
    },
    setDarkMode() {
      this.darkMode = true;
    },
    setLightMode() {
      this.darkMode = false;
    },
  },
  persist: true,
  share: {
    enable: true,
    initialize: true,
  },
});

<template>
  <span class="svg-handler" v-html="svgContent" :style="svgStyle"></span>
</template>

<script setup>
import { watch, ref, computed } from "vue";

const svgContent = ref("");

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: undefined,
  },
});

const fetchSvg = async (url) => {
  try {
    const response = await fetch(url);
    if (response.ok) {
      svgContent.value = await response.text();
    } else {
      console.error("Failed to fetch SVG:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching SVG:", error);
  }
};

watch(
  () => props.icon,
  (newVal) => {
    fetchSvg(newVal);
  },
  { immediate: true }
);

const svgStyle = computed(() => {
  return props.color ? { "--icon-color": props.color } : {};
});
</script>

<style module lang="scss">
svg {
  path {
    // fill: var(--icon-color);
    fill: var(--icon-color, currentColor);
  }
}
</style>

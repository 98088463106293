<template>
  <div class="relative size-full">
    <div
      class="flex justify-center items-center w-full h-full rounded-full overflow-hidden relative bg-zinc-200 dark:bg-zinc-800"
    >
      <!-- Standard img element replacing ion-img -->
      <img
        v-show="avatar && (!avatarLoading || !avatarError)"
        :src="avatar + `?width=${width}`"
        :class="avatarError || avatarLoading ? 'opacity-0' : ''"
        class="w-full h-full cursor-pointer select-none object-cover"
        @load="toggleAvatarLoading"
        @error="toggleAvatarError"
      />
      <div
        v-if="avatarLoading || avatarError"
        class="absolute inset-0 w-full h-full object-cover flex justify-center items-center"
      >
        <!-- Replacing ion-icon with a basic SVG or using a library like FontAwesome -->
        <img
          v-if="!avatarLoading && avatarError"
          src="@/assets/avatar_placeholder.png"
          class="w-full h-full cursor-pointer select-none object-cover"
        />
        <div v-else class="animate-pulse">
          <img
            src="@/assets/avatar_placeholder.png"
            class="w-full h-full cursor-pointer select-none object-cover"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  avatar: {
    type: String,
    required: false,
  },
  id: {
    type: String,
    required: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  width: {
    type: Number,
    default: 40,
  },
});

const avatarLoading = ref(true);
const avatarError = ref(false);

const toggleAvatarLoading = () => {
  avatarLoading.value = false;
};

const toggleAvatarError = () => {
  avatarError.value = true;
  avatarLoading.value = false;
};
</script>

<style scoped></style>

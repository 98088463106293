export const formatViews = (number) => {
  if (number === undefined) {
    return "";
  }

  if (number === 1) {
    return "1 view";
  }

  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M views";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "K views ";
  } else {
    return number.toString() + " views";
  }
};

export const formatViewCount = (number) => {
  if (number === undefined) {
    return "";
  }

  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "K ";
  } else {
    return number.toString();
  }
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const replaceEncodingErrorChars = (char) => {
  const encodingErrors = [
    { broken: "â\x80\x99", fixed: "’" },
    { broken: "â\x80\x9c", fixed: '"' },
  ];
  // If we decide to fix it on frontend, we need to cover some of these chars:
  // € ‚ ƒ „ … † ‡ ˆ ‰ Š ‹ Œ Ž ‘ ’ “ ” • – — ˜ ™ š › œ ž Ÿ

  const f = encodingErrors.find((el) => char.includes(el.broken));
  const { broken, fixed } = f || false;
  return char.replaceAll(broken, fixed);
};

import { ref, onMounted, onBeforeUnmount } from "vue";

export function useMobileDetection() {
  const mobile = ref(false);

  const setMobile = () => {
    const viewportWidth = window.innerWidth;

    const isIoS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    const isAndroid = /Android/i.test(navigator.userAgent);

    mobile.value = viewportWidth < 453 || isIoS || isAndroid;
  };

  onMounted(() => {
    setMobile();
    window.addEventListener("resize", setMobile);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", setMobile);
  });

  return { mobile };
}

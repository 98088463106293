<template>
  <div class="loading-indicator">
    <div>
      <img src="@/assets/playtv/circles.svg" alt="circles" />
    </div>
    <div>{{ message }}<span class="ellipsis"></span></div>
  </div>
</template>
<script setup>
import { computed } from "vue";

const props = defineProps(["title"]);
const message = computed(() => {
  return typeof props.title !== "undefined" ? props.title : "Loading";
});
</script>
<style scoped lang="scss">
.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5em;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--sidebar-toggle-cover-background);

  img {
    animation: rotate 5s linear infinite;
  }

  .ellipsis::after {
    content: "";
    display: inline-block;
    min-width: 14px;
    animation: ellipsis 1.5s steps(4, end) infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes ellipsis {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}
</style>

import { useModal } from "vue-final-modal";
import LogoutConfirmationModal from "@/components/logout/LogoutConfirmationModal.vue";

export const useLogoutConfirmationModal = () => {
  const { open, close } = useModal({
    component: LogoutConfirmationModal,
    attrs: {
      onClose() {
        close();
      },
    },
  });

  return {
    openLogoutConfirmationModal: open,
  };
};

<template>
  <div id="recently-added-videos">
    <router-link
      class="flex items-center justify-start p-4 pb-0 space-x-4 select-none"
      to="/recent-videos"
    >
      <span class="image-container">
        <img :src="ClockCountdownColorized" alt="RecentlyAddedIcon" />
      </span>
      <p class="text-lg font-bold m-0 text-black dark:text-white">
        Recently Added Videos
      </p>
    </router-link>

    <div
      class="video-grid"
      :style="`grid-template-columns: repeat(${columns}, 1fr)`"
      v-if="loading"
    >
      <VideoSkeleton v-for="n in maxVideos" :key="n" />
    </div>
    <div
      class="video-grid"
      :style="`grid-template-columns: repeat(${columns}, 1fr)`"
      v-else
    >
      <VideoPreview
        v-for="video in videosToShow"
        :key="video.id"
        :video="video"
      />
    </div>

    <div
      v-if="!loading && recentlyAddedVideos.length === 0"
      class="text-center"
    >
      No videos
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  nextTick,
  watch,
} from "vue";
import VideoPreview from "@/components/videoPreview/VideoPreview.vue";
import VideoSkeleton from "@/components/VideoSkeleton.vue";
import { apiBackendAuthAxios } from "@/axiosAuth.js";
import ClockCountdownColorized from "@/assets/playtv/icons/ClockCountdownColorized.svg";
import { transformToVideo } from "@/types/Video.tsx";
import { useSidebarStore } from "@/stores/sidebarStore";

const emits = defineEmits(["loadedVideos"]);
const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  onDashboard: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const loading = computed(() => props.loading);
const recentlyAddedVideos = ref([]);
const sidebarStore = useSidebarStore();

const columns = ref(0);

const maxVideos = computed(() => {
  return columns.value * 2;
});

const videosToShow = computed(() => {
  return recentlyAddedVideos.value.slice(0, maxVideos.value);
});

// Observe changes in the grid width and recalculate columns
const handleResizeEventRecentlyAdded = () => {
  let videoGrid = document.querySelector(".video-grid")?.clientWidth;
  console.log("videoGrid " + videoGrid);

  if (typeof videoGrid === "undefined") {
    return;
  }

  if (videoGrid < 550) {
    columns.value = 1;
  } else if (videoGrid < 870) {
    columns.value = 2;
  } else if (videoGrid < 1160) {
    columns.value = 3;
  } else if (videoGrid < 1450) {
    columns.value = 4;
  } else if (videoGrid < 1740) {
    columns.value = 5;
  } else if (videoGrid < 2030) {
    columns.value = 6;
  } else if (videoGrid < 2320) {
    columns.value = 7;
  } else if (videoGrid < 2610) {
    columns.value = 8;
  } else if (videoGrid < 2710) {
    columns.value = 9;
  } else {
    columns.value = 10;
  }

  console.log("columns " + columns.value);
};


onMounted(() => {
  window.addEventListener("resize", handleResizeEventRecentlyAdded);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResizeEventRecentlyAdded);
});

watch(
  () => sidebarStore.isExpanded(),
  () => {
    nextTick(() => {
      handleResizeEventRecentlyAdded();
    });
  }
);

// Load videos
const loadRecentlyAddedVideos = async () => {
  try {
    const {
      data: { data: videoDataIds },
    } = await apiBackendAuthAxios.get("/videos/all");

    if (videoDataIds.length === 0) {
      emits("loadedVideos");
      return;
    }

    const {
      data: { data: videoData },
    } = await apiBackendAuthAxios.post(`/posts/map`, {
      data: videoDataIds.map((v) => v.ulid),
      responseType: "videos",
    });

    const { data } = await apiBackendAuthAxios.post("/profile", {
      ulids: videoData.map((v) => v.user.userId),
    });

    recentlyAddedVideos.value = videoDataIds
      .map((v) => v.ulid)
      .filter((v1) => videoData.filter((v2) => v1 === v2.id).length > 0)
      .map((v1) => {
        return videoData.filter((v2) => v1 === v2.id)[0];
      })
      .map((v) => transformToVideo(v, data[0].data));

    emits("loadedVideos");
  } catch (e) {
    console.error(e);
  }
};

onMounted(() => {
  handleResizeEventRecentlyAdded();
  loadRecentlyAddedVideos();
});
</script>
<style scoped lang="scss">
.video-grid {
  display: grid;
  gap: 1rem;
  width: 100%;
  overflow-x: auto;
}

.skeleton-grid {
  display: grid;
  grid-auto-rows: 1fr;
  gap: 1rem;
  width: 100%;
}

#recently-added-videos {
  width: 100%;
  overflow-x: hidden;
}
</style>
<template>
  <div v-if="showAppBanner" class="py-2 px-4 relative">
    <div class="flex justify-between items-center w-full px-1">
      <div class="flex flex-row items-center gap-x-3">
        <img :src="playTvLogo" alt="PlayTV Logo" class="size-8 rounded-lg" />
        <div class="flex flex-col">
          <span class="font-bold text-sm">PlayTV by Parler</span>
          <span class="text-gray-500 text-xs">Open in the PlayTV app</span>
        </div>
      </div>
      <button
        @click="handleAppPrompt"
        class="rounded-full bg-blue-500 px-2 py-px mr-4 text-md text-white"
      >
        OPEN
      </button>
    </div>
    <!-- Add close X in top corner -->
    <div class="absolute top-0 right-0 p-2">
      <button @click="showAppBanner = false">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="size-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useMobileDetection } from "@/composables/useMobileDetection";
import playTvLogo from "@/assets/playtv/logo/app-logo.png";

const { mobile } = useMobileDetection();
const showAppBanner = ref(false);

const isAndroid = /Android/i.test(navigator.userAgent);

// Show banner only if on Android and on a mobile device
watch(mobile, (isMobile) => {
  if (isMobile && isAndroid) {
    showAppBanner.value = true;
  } else {
    showAppBanner.value = false;
  }
});

onMounted(() => {
  // Show the banner only if the user is on Android and at the top of the page
  if (isAndroid && mobile.value && window.scrollY === 0) {
    showAppBanner.value = true;
  }
});

const handleAppPrompt = () => {
  const androidPlayStoreUrl = "market://details?id=com.parler.play";
  const appScheme = "parlerplay://";

  if (isAndroid) {
    // Attempt to open the app using the custom scheme
    window.location.href = `intent://${appScheme}#Intent;package=com.parler.play;scheme=parlerplay;end`;

    // Add iframe fallback method for Android
    setTimeout(() => {
      if (document.visibilityState === "hidden") {
        // The user is no longer on the page, which means the app has likely opened.
        return;
      }
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = appScheme;
      document.body.appendChild(iframe);

      setTimeout(() => {
        // If the app did not open, redirect to Play Store
        if (document.visibilityState === "visible") {
          window.location.href = androidPlayStoreUrl;
        }
      }, 1000);
    }, 1000);
  }
};
</script>

<template>
  <div id="bursts">
    <router-link
      class="flex items-center justify-start p-4 pb-4 space-x-4 select-none"
      :to="burstUrl"
    >
      <span class="image-container">
        <img :src="burstIcon" alt="BurstIcon" />
      </span>
      <p class="text-lg font-bold m-0 text-black dark:text-white">Bursts</p>
    </router-link>

    <BurstScroller
      :loading="loading"
      :loadingMore="loadingMore"
      :bursts="bursts"
      :number-of-bursts="numberOfBursts"
      :nextCursor="nextCursor"
      :end-of-bursts="endOfBursts"
      @load-more-bursts="loadBursts"
    />

    <div v-if="!loading && bursts.length === 0" class="text-center">
      No bursts
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { apiBackendAuthAxios } from "@/axiosAuth.js";
import burstIcon from "@/assets/playtv/icons/burst-icon.svg";
import { transformToVideo } from "@/types/Video.tsx";
import BurstScroller from "@/components/feed/BurstScroller.vue";

const emits = defineEmits(["loadedVideos"]);
const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  onDashboard: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const loading = computed(() => props.loading);
const loadingMore = ref(false);
const nextCursor = ref(null);
const bursts = ref([]);
const endOfBursts = ref(false);
const numberOfBursts = ref(20);

const burstUrl = computed(() => {
  return `/b/${bursts.value.length > 0 ? bursts.value[0].id : ""}`;
});

// Load videos
const loadBursts = async () => {
  if (loadingMore.value || endOfBursts.value) {
    return;
  }
  if (nextCursor.value !== null) {
    loadingMore.value = true;
  }
  try {
    const cursorParam = nextCursor.value ? `?cursor=${nextCursor.value}` : "";
    const {
      data: { data: videoDataIds, next_cursor: cursor },
    } = await apiBackendAuthAxios.get(`/bursts/all${cursorParam}`);

    numberOfBursts.value = videoDataIds.length;

    if (cursor === null) {
      endOfBursts.value = true;
    }

    nextCursor.value = cursor;

    if (videoDataIds.length === 0) {
      emits("loadedVideos");
      return;
    }

    const {
      data: { data: videoData },
    } = await apiBackendAuthAxios.post(`/posts/map`, {
      data: videoDataIds.map((v) => v.ulid),
      responseType: "videos",
    });

    const { data } = await apiBackendAuthAxios.post("/profile", {
      ulids: videoData.map((v) => v.user.userId),
    });

    if (bursts.value.length === 0) {
      bursts.value = videoDataIds
        .map((v) => v.ulid)
        .filter((v1) => videoData.filter((v2) => v1 === v2.id).length > 0)
        .map((v1) => {
          return videoData.filter((v2) => v1 === v2.id)[0];
        })
        .map((v) => transformToVideo(v, data[0].data));
    } else {
      bursts.value = [
        ...bursts.value,
        ...videoDataIds
          .map((v) => v.ulid)
          .filter((v1) => videoData.filter((v2) => v1 === v2.id).length > 0)
          .map((v1) => {
            return videoData.filter((v2) => v1 === v2.id)[0];
          })
          .map((v) => transformToVideo(v, data[0].data)),
      ];
    }

    emits("loadedVideos");
  } catch (e) {
    console.error(e);
  } finally {
    loadingMore.value = false;
  }
};

onMounted(() => {
  loadBursts();
});
</script>

<template>
  <router-link
    :to="to"
    @click="handleClick"
    :class="['button', isActive() ? 'active' : '']"
  >
    <div class="inline-flex">
      <img
        v-if="to === '/following'"
        :src="icon"
        alt="Icon"
        class="dark:invert"
      />
      <span v-else class="material-icons">
        <span v-html="svgContent"></span>
      </span>
    </div>
    <span class="text" :class="to === '/following' ? 'pl-4' : ''">{{
      label
    }}</span>
  </router-link>
</template>
<script setup>
import { ref, watch, computed } from "vue";
import { useRoute } from "vue-router";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  to: {
    type: String,
    required: true,
  },
  paths: {
    type: [Array, String],
    default: () => [],
  },
  icon: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["linkClicked"]);
const route = useRoute();
const svgContent = ref("");
const currentPath = computed(() => route.path);

const fetchSvg = async (url) => {
  try {
    const response = await fetch(url);
    if (response.ok) {
      svgContent.value = await response.text();
    } else {
      console.error("Failed to fetch SVG:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching SVG:", error);
  }
};

const isActive = () => {
  const pathsArray =
    typeof props.paths === "string"
      ? props.paths
          .replace(/[\n\s]/g, "")
          .trim()
          .split(",")
      : props.paths;

  if (pathsArray.length === 0) {
    return currentPath.value === props.to;
  } else {
    return pathsArray.some((path) => path === currentPath.value);
  }
};

watch(
  () => props.icon,
  (newVal) => {
    fetchSvg(newVal);
  },
  { immediate: true }
);

const handleClick = () => {
  emit("linkClicked");
};
</script>

<style scoped lang="scss">
.button {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  // transition: 0.2s ease-in-out;
  padding: 0.5rem 1rem;
  color: inherit;
  cursor: pointer;

  .material-icons {
    margin-right: 1rem;
    display: inline-flex;
    font-size: 2rem;
    color: var(--light);
    // transition: 0.2s ease-in-out;
  }

  .text {
    color: var(--light);
    // transition: 0.2s ease-in-out;
    font-size: 14px;
  }

  .avatar {
    position: relative;

    &.inline-flex {
      margin-left: 0;
    }

    .material-icons {
      border-radius: 50%;
      overflow: hidden;
      height: 36px;
      width: 36px;
    }

    & + .username > .flex:nth-child(1) {
      flex-grow: 4;
    }
  }

  &:hover {
    background-color: var(--dark-alt);

    .material-icons,
    .text {
      color: var(--primary);
    }
  }

  .inline-flex {
    margin-left: 0.5em;
  }

  &.active {
    background-color: var(--sidebar-button-active-background);

    &:before {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--sidebar-button-active-highlight);
      display: block;
      width: 4px;
      height: 100%;
    }
  }

  &.router-link-exact-active {
    .material-icons,
    .text {
      color: var(--primary);
    }
  }
}

aside .menu svg path {
  fill: var(--sidebar-icon-color);
}
</style>

import { defineStore } from "pinia";
import { useAuthStore } from "@/stores/auth.js";
import { apiBackendAuthAxios, apiBackendAxios } from "@/axiosAuth.js";

// @ts-ignore
export const useGlobalStore = defineStore("globalStore", {
  state: () => {
    return {
      isUpdatingAccessToken: false,
      isMobile: false,
      reactionTypes: [],
    };
  },
  actions: {
    setUpdatingAccessToken(value) {
      this.isUpdatingAccessToken = value;
    },
    setMobile(value) {
      this.isMobile = value;
    },
    async setReactionTypes() {
      const authStore = useAuthStore();
      try {
        let response = null;

        if (!authStore.authenticated) {
          response = await apiBackendAxios.get("/v3/settings/reactions");
          this.reactionTypes = response.data.data;
        } else {
          response = await apiBackendAuthAxios.get("/settings/reactions");
          this.reactionTypes = response.data.data;
        }
      } catch (error) {
        console.error(error);
        console.log("Error setting reaction types");
      }
    },
  },
});
